import React from "react"
import PropTypes from "prop-types";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import {Link} from "gatsby";
import createValidLink from "../helpers/urlFixer";

const AppCard = ({ title, description, icon, link }) => {
	const iconUrl = icon.localFile?.childImageSharp.gatsbyImageData;
	const altText = icon.altText || "";

	const appLink = createValidLink(link);

	return (
		<div className="app-card">
			<div className="app-card__header">
				<Link to={appLink} className="app-card__image-wrapper" aria-label={`Read more about ${title}`}>
					<Image
						className="post-card__image"
						image={iconUrl}
						alt={altText || "post-card-image"}
						title={altText || "post-card-image"}
					/>
				</Link>

				<div className="app-card__info">
					<Link to={appLink} className="app-card__title">
						{title}
					</Link>

					<p className="app-card__subtitle">
						by UTD
					</p>
				</div>
			</div>

			<div className="app-card__body">
				<p className="app-card__text">
					{description}
				</p>

				<div className="app-card__link-wrapper">
					<Link to={appLink} className="app-card__link">
						Read more
					</Link>
				</div>
			</div>
		</div>
	);
};

AppCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};

export default AppCard;
