import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { bannerSubtitleAnimation } from "../../settings/generalAnimations";
import PageHeader from "../../components/PageHeader";

const AppsBanner = ({ title, subtitle, icons, pageHeader }) => {
	return (
		<section className="apps-banner">
			<div className="container">
				<PageHeader
					{...pageHeader}
					title={pageHeader?.title || title}
					implemented={true}
					className={"section-title section-title--large apps-banner__title"}
					isGeneral={true}
				/>

				{icons && icons.length > 0 &&
					<motion.div
						className="apps-banner__icons"
						{...bannerSubtitleAnimation}
					>
						{icons.map(({ icon }, index) => {
							const image = icon.localFile?.childImageSharp?.gatsbyImageData;
							const altText = icon.altText || "";

							if (!image) {
								return null;
							}

							return (
								<div
									key={index}
									className="apps-banner__icons-item"
								>
									<Image
										className="apps-banner__icons-image"
										image={image}
										alt={altText || title}
										title={altText || title}
									/>
								</div>
							);
						})}
					</motion.div>
				}

				{subtitle && (
					<motion.p
						className="section-subtitle apps-banner__subtitle"
						{...bannerSubtitleAnimation}
					>
						{subtitle}
					</motion.p>
				)}
			</div>
		</section>
	);
};

AppsBanner.props = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	icons: PropTypes.array
};

export default AppsBanner;
