import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import classNames from "classnames";
import { getPaginationModel, ITEM_TYPES } from "ultimate-pagination";
import IconEllipsis from "../icons/IconEllipsis";

const Pagination = ({ pagesCount, currentPage, originalPath, containerClasses }) => {
	if(pagesCount === 1) {
		return null;
	}

	const paginationModel = getPaginationModel({
		currentPage,
		totalPages: pagesCount,
		siblingPagesRange: 1,
		hideFirstAndLastPageLinks: true,
	});

	const getPageLinkByPageNumber = (number) => {
		if(number === 1) {
			return `${originalPath}/`;
		}

		return `${originalPath}/${number}/`;
	};

	const getPaginationItem = ({ type, value, isActive, key }) => {
		switch (type) {
			case ITEM_TYPES.PREVIOUS_PAGE_LINK: {
				return (
					<Link
						key={key}
						to={getPageLinkByPageNumber(value)}
						className={classNames({
							"pagination__arrow": true,
							"pagination__arrow--left": true,
							"pagination__arrow--disabled": isActive
						})}
						aria-label="Previous page"
					>
						Prev
					</Link>
				);
			}

			case ITEM_TYPES.PAGE: {
				return (
					<Link
						key={key}
						to={getPageLinkByPageNumber(value)}
						className={classNames({
							"pagination__item": true,
							"pagination__item--page": true,
							"pagination__item--active": isActive
						})}
						aria-label={`Go to ${value} page`}
					>
						{value}
					</Link>
				);
			}

			case ITEM_TYPES.ELLIPSIS: {
				return (
					<div
						key={key}
						className="pagination__item pagination__item--ellipsis"
					>
						<IconEllipsis />
					</div>
				);
			}

			case ITEM_TYPES.NEXT_PAGE_LINK: {
				return (
					<Link
						key={key}
						to={getPageLinkByPageNumber(value)}
						className={classNames({
							"pagination__arrow": true,
							"pagination__arrow--right": true,
							"pagination__arrow--disabled": isActive
						})}
						aria-label="Next page"
					>
						Next
					</Link>
				);
			}

			default: {
				return null;
			}
		}
	};

	const wrapperClasses = classNames("pagination__wrapper", "container", "container--mobile-small", containerClasses);

	return (
		<div className="pagination">
			<div className={wrapperClasses}>
				<div className="pagination__items">
					{paginationModel.map(getPaginationItem)}
				</div>
			</div>
		</div>
	);
};

Pagination.propTypes = {
	pagesCount: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	originalPath: PropTypes.string.isRequired,
	containerClasses: PropTypes.string
};

export default Pagination;
