import React from "react";
import PropTypes from "prop-types";
import AppCard from "../../components/AppCard";

const AppsList = ({ apps }) => {
	const appsArray = [...apps];

	return (
		<div className="apps-list">
			<div className="container container--mobile-small">
				<div className="apps-list__wrapper">
					{appsArray.map((app, index) => {
						return (
							<div
								key={index}
								className="apps-list__item"
							>
								<AppCard
									title={app.title}
									description={app.acfApp?.description}
									icon={app.acfApp?.icon}
									link={app.link}
								/>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	);
};

AppsList.props = {
	apps: PropTypes.array.isRequired
};

export default AppsList;