import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";

import AppsBanner from "../sections/Apps/AppsBanner";
import AppsList from "../sections/Apps/AppsList";
import Pagination from "../components/Pagination";
import DefaultHelmet from "../seo/DefaultHelmet";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const Apps = ({ pageContext }) => {
	const {
		apps,
		pagesCount,
		currentPage,
		originPath,
		pageBanner,
		metaData,
		pageHeader,
	} = pageContext;

	const pageTitle = metaData?.title || "Apps";
	const pageDescription = metaData?.description || "UTD Applications list";

	useBeaconWidget(beaconWidgetIds.APP);

	return (
		<DefaultLayout>
			<DefaultHelmet title={pageTitle} description={pageDescription} />

			<AppsBanner
				{...pageBanner}
				pageHeader={pageHeader}
			/>

			<AppsList
				apps={apps}
			/>

			<Pagination
				pagesCount={pagesCount}
				currentPage={currentPage}
				originalPath={originPath}
			/>
		</DefaultLayout>
	);
};

export default Apps;
